input:focus {
  background-color: whitesmoke !important;
  color: black !important;
}

.cs-hover {
  cursor: pointer;
}

.MuiButton-root {
  background-color: whitesmoke !important;
  color: black !important;
  margin: 5px !important;
}

.MuiButton-containedPrimary {
  background-color: cornflowerblue !important;
}

.MuiButton-containedSecondary {
  background-color: cornflowerblue !important;
}