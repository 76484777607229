.progress {
    height: 1.5rem;
    overflow: hidden;
    background-color: grey;
    border-radius: .25rem;
}
.progress-bar {
    display: inline-block;
    height: 100%;
}
.bg-critical {
    background-color: #aa0baa;
}
.bg-high {
    background-color: #d9534f;
}
.bg-medium {
    background-color: #fd761c!important;
}
.bg-low {
    background-color: #ffd000!important;
}